<template>
  <v-card class="home">
    <v-card-title class="d-flex justify-center h-100 pa-0">
      <iframe
        src="https://dashboard.spedimo.eu"
        width="100%"
        height="100%"
        frameBorder="0"
      />
    </v-card-title>
  </v-card>
</template>

<script>
export default {
  name: 'Home'
}
</script>

<style lang="scss" scoped>
.home {
  height: calc(100vh - 48px - 60px);
}
</style>
